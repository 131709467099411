import { HelpPopover } from "@/components/ui/help-popover";
import {
  selectQualityColorCoding,
  selectRegistrationEdgeType,
  selectScanColoring,
  selectShowConnectionLines,
  selectShowDiscardedConnectionLines,
} from "@/data-preparation-tool/store/data-preparation-view-options/data-preparation-view-options-selectors";
import {
  ScanColoring,
  setQualityColorCoding,
  setRegistrationEdgeType,
  setScanColoring,
  setShowConnectionLines,
  setShowDiscardedConnectionLines,
} from "@/data-preparation-tool/store/data-preparation-view-options/data-preparation-view-options-slice";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  FaroMenu,
  FaroMenuProps,
  FaroRadio,
  FaroRadioGroup,
  FaroSwitch,
  FaroText,
  neutral,
} from "@faro-lotv/flat-ui";
import { validateEnumValue } from "@faro-lotv/foundation";
import { RegistrationEdgeType } from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";

type DataPreparationViewSettingsMenuProps = Pick<
  FaroMenuProps,
  "open" | "anchorEl" | "onClose"
>;

/** @returns the popover menu containing the data-preparation view settings */
export function DataPreparationViewSettingsMenu({
  open,
  anchorEl,
  onClose,
}: DataPreparationViewSettingsMenuProps): JSX.Element {
  const isRegistrationDev = useAppSelector(
    selectHasFeature(Features.RegistrationDev),
  );
  const showDiscardedConnectionLines = useAppSelector(
    selectShowDiscardedConnectionLines,
  );
  const dispatch = useAppDispatch();

  return (
    <FaroMenu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: -2,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      dark
    >
      <Stack gap={1} p={1} sx={{ minWidth: "291px" }}>
        <FaroText
          variant="heading12"
          color={neutral[100]}
          sx={{ textTransform: "uppercase", fontSize: "10px" }}
          pb={1}
        >
          Registration View Settings
        </FaroText>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FaroText variant="heading12" color={neutral[100]}>
            Scan colors
          </FaroText>

          <HelpPopover
            title="Scan colors"
            description="Configure in which colors scans will be displayed in this view. Color by scan will display each scan in a different color. Original color will display the original capture colors."
          />
        </Stack>
        <ScanColoringRadio />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FaroText variant="heading12" color={neutral[100]}>
            Registration Connections
          </FaroText>

          <HelpPopover
            title="Registration Connections"
            description="Show connections between scans that have been registered together."
          />
        </Stack>
        <RegistrationRevisionConnectionsGroup />

        {isRegistrationDev && (
          <FaroSwitch
            dark
            checked={showDiscardedConnectionLines}
            label="Show discarded connections (DEV)"
            onToggle={() =>
              dispatch(
                setShowDiscardedConnectionLines(!showDiscardedConnectionLines),
              )
            }
          />
        )}
      </Stack>
    </FaroMenu>
  );
}

function isScanColoring(value: string): value is ScanColoring {
  return Object.keys(ScanColoring).includes(value);
}

function RegistrationRevisionConnectionsGroup(): JSX.Element {
  const showConnectionLines = useAppSelector(selectShowConnectionLines);
  const qualityColorCoding = useAppSelector(selectQualityColorCoding);
  const registrationEdgeType = useAppSelector(selectRegistrationEdgeType);
  const dispatch = useAppDispatch();

  return (
    <FaroRadioGroup
      value={registrationEdgeType}
      onChange={(_, value) => {
        if (validateEnumValue(value, RegistrationEdgeType)) {
          dispatch(setRegistrationEdgeType(value));
        }
      }}
    >
      <FaroSwitch
        dark
        checked={showConnectionLines}
        label="Show connection lines"
        onToggle={() => dispatch(setShowConnectionLines(!showConnectionLines))}
      />
      <FaroSwitch
        dark
        checked={qualityColorCoding}
        label="Quality color coding"
        onToggle={() => dispatch(setQualityColorCoding(!qualityColorCoding))}
      />
      {/* Display the view for the active step */}
      <Stack
        justifyContent="space-between"
        sx={{
          ml: 3,
          opacity: qualityColorCoding ? 1 : 0.5,
          pointerEvents: qualityColorCoding ? "auto" : "none",
        }}
      >
        <FaroRadio
          dark
          value={RegistrationEdgeType.global}
          label="Global Results"
        />
        <FaroRadio
          dark
          value={RegistrationEdgeType.local}
          label="Local Results"
        />
        <FaroRadio
          dark
          value={RegistrationEdgeType.preReg}
          label="Pre Registration Results"
        />
        <FaroRadio
          dark
          value={RegistrationEdgeType.slam}
          label="Slam Results"
        />
      </Stack>
    </FaroRadioGroup>
  );
}

function ScanColoringRadio(): JSX.Element {
  const scanColoring = useAppSelector(selectScanColoring);
  const dispatch = useAppDispatch();

  return (
    <FaroRadioGroup
      value={scanColoring}
      onChange={(_, value) => {
        if (isScanColoring(value)) {
          dispatch(setScanColoring(value));
        }
      }}
    >
      <FaroRadio dark value={ScanColoring.byScan} label="Color by scan" />
      <FaroRadio
        dark
        value={ScanColoring.originalColor}
        label="Original color"
      />
    </FaroRadioGroup>
  );
}
