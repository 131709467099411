import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { changeMode } from "@/store/mode-slice";
import { setWalkSceneFilter } from "@/store/modes/walk-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { SceneFilter } from "@/types/scene-filter";
import { blue } from "@faro-lotv/flat-ui";
import { IElementImg360 } from "@faro-lotv/ielement-types";
import { CameraMonitor } from "@faro-lotv/lotv";
import { UPDATE_CAMERA_MONITOR_PRIORITY } from "@faro-lotv/spatial-ui";
import { useFrame, useThree } from "@react-three/fiber";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Camera, Frustum, OrthographicCamera, Vector3 } from "three";
import { useWaypointReference } from "../utils/placeholder-preview";
import { TextLabel } from "./text-label";

/** The maximum number of visible waypoints to display labels in 2D view */
const MAX_NUMBER_OF_LABELS_2D_VIEW = 100;
/** The maximum number of visible waypoints to display labels in 3D view */
const MAX_NUMBER_OF_LABELS_3D_VIEW = 20;

type WaypointPosition = {
  /** The waypoint to show */
  pano: IElementImg360;

  /** The location in world space where waypoint will be rendered */
  renderPosition: Vector3;
};

type WaypointLabelRenderProps = {
  /** The list of WaypointPositions */
  waypoints: WaypointPosition[];
};

/**
 * @returns waypoint labels
 */
export function WaypointLabelRender({
  waypoints,
}: WaypointLabelRenderProps): JSX.Element | null {
  const labelContainer = useViewOverlayRef();

  const [waypointLabels, setWaypointLabels] = useState<WaypointPosition[]>([]);

  // Create a camera monitor to know when the camera is still
  const camera = useThree((s) => s.camera);
  const cameraMonitor = useMemo(() => new CameraMonitor(), []);
  useFrame(({ camera }, delta) => {
    cameraMonitor.checkCameraMovement(camera, delta);
  }, UPDATE_CAMERA_MONITOR_PRIORITY);

  useEffect(() => {
    const cameraStoppedConnection = cameraMonitor.cameraStoppedMoving.on(() => {
      const frustum = new Frustum();
      frustum.setFromProjectionMatrix(camera.projectionMatrix);
      frustum.planes.forEach((plane) => {
        plane.applyMatrix4(camera.matrixWorld);
      });
      const waypointsInView = waypoints.filter((waypoint) =>
        frustum.containsPoint(waypoint.renderPosition),
      );

      if (camera instanceof OrthographicCamera) {
        setWaypointLabels(
          waypointsInView.length > MAX_NUMBER_OF_LABELS_2D_VIEW
            ? []
            : waypointsInView,
        );
      } else {
        setWaypointLabels(findClosestWaypoints(waypointsInView, camera));
      }
    });
    return () => {
      cameraStoppedConnection.dispose();
    };
  }, [camera, cameraMonitor.cameraStoppedMoving, waypoints]);

  if (waypointLabels.length === 0) {
    return null;
  }

  return (
    <>
      {waypointLabels.map((waypoint) => (
        <WaypointLabel
          key={waypoint.pano.id}
          pano={waypoint.pano}
          position={waypoint.renderPosition}
          parentRef={labelContainer}
        />
      ))}
    </>
  );
}

type WaypointLabelProps = {
  /** The panorama to show waypoint label */
  pano: IElementImg360;
  /** The location in world space where way point will be shown */
  position: Vector3;
  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;
};

const MAX_LABEL_LENGTH = 9;
/**
 * @returns a Html label that displays waypoint label and moves in the 3D scene along with a given 3D position.
 */
function WaypointLabel({
  pano,
  position,
  parentRef,
}: WaypointLabelProps): JSX.Element {
  const referenceElement = useWaypointReference(pano);

  // truncate name in the middle if it is too long, use truncated as shortLabel and keep the full name as the fullLabel
  const { shortLabel, fullLabel } = useMemo(() => {
    const fullLabel = referenceElement?.name ?? pano.name;
    const shortLabel =
      fullLabel.length > MAX_LABEL_LENGTH
        ? `${fullLabel.slice(0, 3)}...${fullLabel.slice(fullLabel.length - 3)}`
        : fullLabel;
    return { shortLabel, fullLabel };
  }, [pano.name, referenceElement]);

  const [hovered, setHovered] = useState<boolean>();
  const dispatch = useAppDispatch();
  const onClick = useCallback(() => {
    dispatch(setActiveElement(pano.id));
    dispatch(setWalkSceneFilter(SceneFilter.Pano));
    dispatch(changeMode("walk"));
  }, [dispatch, pano.id]);

  return (
    <TextLabel
      index={0}
      text={hovered ? fullLabel : shortLabel}
      position={position}
      parentRef={parentRef}
      visible
      active
      activeCursor="pointer"
      transparent
      pointerEvents="auto"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      placement="bottom"
      backgroundColor={blue[800]}
      padding={0.25}
      fontSize="0.625em"
    />
  );
}

/**
 * @param waypoints the given waypoints from which to find the MAX_NUMBER_OF_LABELS_3D_VIEW waypoints that are closest to a given camera
 * @param camera the given camera
 * @returns the MAX_NUMBER_OF_LABELS_3D_VIEW waypoints that are closest to the camera
 */
function findClosestWaypoints(
  waypoints: WaypointPosition[],
  camera: Camera,
): WaypointPosition[] {
  if (waypoints.length <= MAX_NUMBER_OF_LABELS_3D_VIEW) {
    return waypoints;
  }

  waypoints.sort(
    (point1, point2) =>
      point1.renderPosition.distanceTo(camera.position) -
      point2.renderPosition.distanceTo(camera.position),
  );

  return waypoints.slice(0, MAX_NUMBER_OF_LABELS_3D_VIEW);
}
