import {
  FaroText,
  neutral,
  PointCloudSmallIcon,
  WorldCoordinateIcon,
} from "@faro-lotv/flat-ui";
import {
  convertToDateString,
  isLoading,
  Loading,
  SignedUrl,
} from "@faro-lotv/foundation";
import { ISOTimeString, URI } from "@faro-lotv/ielement-types";
import { useValidUrl } from "@faro-lotv/project-source";
import {
  Card,
  CardMedia,
  Fade,
  Popper,
  PopperProps,
  Skeleton,
  useTheme,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useMemo, useState } from "react";
import { Vector3Tuple } from "three";

/** The height of the preview image in px */
const PREVIEW_IMAGE_HEIGHT = 180;

type PlaceholderPreviewBaseProps = {
  /** The name of the placeholder. */
  name?: string;

  /** The URL to the image to render. */
  imageUri?: URI | SignedUrl | null | Loading;

  /** ISO 8601 timestamp when the element has been created. */
  createdAt?: ISOTimeString;

  /** Whether the preview should be visible */
  isVisible?: boolean;

  /** The anchor element to attach the preview to. */
  anchorEl?: PopperProps["anchorEl"];

  /** The name of the dataset the placeholder belongs to. */
  datasetName?: string;

  /** The coordinates of the placeholder. */
  coordinates?: Vector3Tuple;

  /** Additional modifiers to add to the popper component. */
  popperModifiers?: PopperProps["modifiers"];
};

/**
 * @returns  A dialog that displays a preview of an Img360 placeholder on hover,
 * while handling warm-up and cool-down transitions. The warm-up transition
 * introduces a delay before showing the preview window upon hovering,
 * ensuring a smooth and deliberate interaction. The cool-down transition
 * introduces a delay before hiding the preview window after moving the
 * pointer away, preventing flickering or abrupt disappearance.
 *
 * These transitions contribute to a better user experience by providing a
 * subtle delay for previewing and dismissing the content, reducing visual
 * noise and creating a more polished interaction.
 */
export function PlaceholderPreviewBase({
  name,
  imageUri,
  createdAt,
  isVisible,
  anchorEl,
  datasetName,
  coordinates,
  popperModifiers,
}: PlaceholderPreviewBaseProps): JSX.Element | null {
  const theme = useTheme();

  /**
   * The duration used for both fade in/out of window as well as the warm up and cool down time to make the window visible/invisible
   * i.e handles the delay between pointer hover and the popup visibility both while hovering in (warm-up) and hovering out (cool-down)
   */
  const transitionDuration = theme.transitions.duration.shorter;

  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [isPreviewLoading, setIsPreviewImageLoading] = useState(true);

  const isImageLoading =
    isLoading(imageUri) || (isPreviewLoading && !!imageUri);
  const cardImage = isLoading(imageUri) ? undefined : imageUri ?? undefined;
  const signedCardImage = useValidUrl(cardImage);

  const formattedCoordinates = useMemo(() => {
    if (coordinates) {
      return `X ${coordinates[0].toFixed(3)}, Y ${coordinates[1].toFixed(3)}, Z ${coordinates[2].toFixed(3)}`;
    }
  }, [coordinates]);

  return (
    <Fade in={isVisible} timeout={transitionDuration}>
      <Popper
        open
        placement="bottom"
        disablePortal
        anchorEl={anchorEl}
        modifiers={[
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
          {
            name: "applyArrowHide",
            enabled: true,
            phase: "write",
            fn({ state }) {
              if (arrowRef) {
                if (state.placement === "bottom") {
                  arrowRef.style.visibility = "visible";
                } else {
                  // Hide the arrow if the popper can't be displayed below the anchor
                  arrowRef.style.visibility = "hidden";
                }
              }
            },
          },
          ...(popperModifiers ?? []),
        ]}
        sx={{ pointerEvents: "none" }}
      >
        {/* arrow element */}
        <Box
          component="span"
          ref={setArrowRef}
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            "&::after": {
              backgroundColor: theme.palette.gray950,
              borderTopLeftRadius: 4,
              content: '""',
              position: "absolute",
              width: 12,
              height: 12,
              top: -6,
              transform: "rotate(45deg)",
            },
          }}
        />
        <Card
          sx={{
            minWidth: 400,
            background: theme.palette.gray950,
            padding: "12px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: "10px" }}
          >
            <FaroText variant="labelM" color={neutral[100]}>
              {name}
            </FaroText>
            <FaroText variant="bodyXS" color={neutral[100]}>
              {createdAt ? convertToDateString(createdAt) : undefined}
            </FaroText>
          </Stack>

          {isImageLoading && (
            <Box component="div" height={PREVIEW_IMAGE_HEIGHT}>
              <Skeleton
                variant="rectangular"
                height="100%"
                sx={{ background: neutral[800] }}
              />
            </Box>
          )}

          <CardMedia
            component="img"
            height={isImageLoading || !imageUri ? 0 : PREVIEW_IMAGE_HEIGHT}
            width="100%"
            onLoad={() => setIsPreviewImageLoading(false)}
            onError={() => setIsPreviewImageLoading(false)}
            image={signedCardImage}
            sx={{
              objectFit: "fill",
            }}
          />
          <PreviewBottomInfo
            datasetName={datasetName}
            coordinates={formattedCoordinates}
          />
        </Card>
      </Popper>
    </Fade>
  );
}

type PreviewBottomInfoProps = Pick<
  PlaceholderPreviewBaseProps,
  "datasetName"
> & {
  /** The formatted coordinates of the pano */
  coordinates?: string;
};

function PreviewBottomInfo({
  datasetName,
  coordinates,
}: PreviewBottomInfoProps): JSX.Element | null {
  if (!datasetName && !coordinates) return null;

  return (
    <Stack flexDirection="row" alignItems="flex-start" sx={{ mt: "10px" }}>
      <Stack direction="row" gap={0.5} alignItems="flex-start" width="50%">
        {datasetName && (
          <PointCloudSmallIcon sx={{ color: neutral[100], fontSize: "14px" }} />
        )}
        <FaroText variant="bodyXS" color={neutral[100]}>
          {datasetName}
        </FaroText>
      </Stack>
      <Stack
        direction="row"
        gap={0.5}
        alignItems="flex-start"
        width="50%"
        justifyContent="flex-end"
      >
        {coordinates && (
          <WorldCoordinateIcon sx={{ color: neutral[100], fontSize: "14px" }} />
        )}
        <FaroText variant="bodyXS" color={neutral[100]}>
          {coordinates}
        </FaroText>
      </Stack>
    </Stack>
  );
}
