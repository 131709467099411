import { FaroStep } from "@faro-lotv/flat-ui";
import { useMemo } from "react";
import { AlignmentStepper } from "../alignment-modes-commons/alignment-stepper";

interface LayerToLayerProgressBarProps {
  /** A callback that is called when the user wants to apply the alignment */
  apply(): void;
}

/**
 * This defines all the steps to align layer to layer
 */
enum LayerToLayerAlignmentStep {
  alignSheet = "alignSheet",
}

/**
 * @returns The bar that is shown in the top of a layer-to-layer alignment mode
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function LayerToLayerAlignmentProgressBar({
  apply,
}: LayerToLayerProgressBarProps): JSX.Element {
  /** defines steps for LayerToLayer Alignment progressBar */
  const steps: FaroStep[] = useMemo(
    () => [
      {
        key: LayerToLayerAlignmentStep.alignSheet,
        label: "Align",
      },

      // TODO: Add onBeforeNext when second step will be implemented
    ],
    [],
  );

  return (
    <AlignmentStepper
      steps={steps}
      lastStepButtonText="Confirm Alignment"
      onLastStepButtonClicked={apply}
    />
  );
}
